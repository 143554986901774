(function ($) {
    $('.header__menu, .header__burger-box').on('click', function() {
        $('.header__menu, .header__burger').toggleClass('active');
        $('body').toggleClass('lock');
    })

    $(".header__menu-item, .footer__menu-item").on('click', function() { // ID откуда кливаем
        $('html, body').animate({
            scrollTop: $($(this).attr("href")).offset().top  // класс объекта к которому приезжаем
        }, 1000); // Скорость прокрутки
    });
})(jQuery);